import React, { useState, useEffect } from 'react';
import axios from 'axios';

const VisitMetrics = () => {
    const [range, setRange] = useState('7'); // Rango por defecto: Últimos 7 días
    const [branch, setBranch] = useState('1'); // Sucursal por defecto: 1
    const [totalVisits, setTotalVisits] = useState(null);

    useEffect(() => {
        const fetchVisits = async () => {
            try {
                const response = await axios.get(
                    `https://pedir-pedir.p89nps.easypanel.host/metrics/visits?range=${range}`
                );
                setTotalVisits(response.data.totalVisits); // Actualiza el estado con las visitas totales
            } catch (error) {
                console.error('Error fetching visit metrics:', error);
            }
        };
    
        fetchVisits(); // Llama a la función asincrónica
    }, [range]); // Escucha los cambios en el rango
        

    return (
        <div>
            <h2>Métricas de Visitas a la web</h2>
            <select value={range} onChange={(e) => setRange(e.target.value)}>
                <option value="today">Hoy</option>
                <option value="7">Últimos 7 días</option>
                <option value="30">Últimos 30 días</option>
                <option value="180">Últimos 6 meses</option>
                <option value="365">Último año</option>
            </select>

            <div className="visit-summary">
                {totalVisits !== null ? (
                    <h3>Total de Visitas: {totalVisits}</h3>
                ) : (
                    <p>Cargando visitas...</p>
                )}
            </div>
        </div>
    );
};

export default VisitMetrics;

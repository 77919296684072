import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const MeatAndBreadMetrics = () => {
    const [meatMetrics, setMeatMetrics] = useState(0);
    const [breadMetrics, setBreadMetrics] = useState(0);
    const [range, setRange] = useState('30');  // Estado para el rango de fechas
    const [branch, setBranch] = useState('1');  // Estado para la sucursal
    const [loading, setLoading] = useState(true);

    const fetchMetrics = async () => {
        try {
            setLoading(true);
            const response = await axios.get(
                `https://pedir-pedir.p89nps.easypanel.host/metrics/carnes-panes?range=${range}&branch=${branch}`
            );
            console.log(response.data); // Verifica los datos
            setMeatMetrics(response.data.totalCarnes);  // Asignamos el total de carnes
            setBreadMetrics(response.data.totalPanes);  // Asignamos el total de panes
            setLoading(false);
        } catch (error) {
            console.error('Error fetching meat and bread metrics:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMetrics();
    }, [range, branch]);  // Se vuelve a llamar cuando cambian los filtros

    const meatData = {
        labels: [`Carnes: ${meatMetrics}`],
        datasets: [
            {
                data: [meatMetrics],
                backgroundColor: ['#ff6384'],
                hoverBackgroundColor: ['#ff4d6b'],
            },
        ],
        options: {
            plugins: {
                legend: {
                    labels: {
                        generateLabels: (chart) => {
                            const original = ChartJS.defaults.plugins.legend.labels.generateLabels;
                            const labels = original(chart);
                            // Modifica las etiquetas para agregar la cantidad
                            labels[0].text = `Carnes: ${meatMetrics}`;  // Etiqueta con la cantidad
                            return labels;
                        }
                    }
                }
            }
        }
    };

    const breadData = {
        labels: [`Panes: ${breadMetrics}`],
        datasets: [
            {
                data: [breadMetrics],
                backgroundColor: ['#36a2eb'],
                hoverBackgroundColor: ['#4da6ff'],
            },
        ],
        options: {
            plugins: {
                legend: {
                    labels: {
                        generateLabels: (chart) => {
                            const original = ChartJS.defaults.plugins.legend.labels.generateLabels;
                            const labels = original(chart);
                            // Modifica las etiquetas para agregar la cantidad
                            labels[0].text = `Panes: ${breadMetrics}`;  // Etiqueta con la cantidad
                            return labels;
                        }
                    }
                }
            }
        }
    };

    return (
        <div>
            {/* Filtros */}
            <div>
                <label>
                    Sucursal:
                    <select value={branch} onChange={(e) => setBranch(e.target.value)}>
                        <option value="1">Sucursal 1</option>
                        <option value="2">Sucursal 2</option>
                    </select>
                </label>

                <label>
                    Rango de Fechas:
                    <select value={range} onChange={(e) => setRange(e.target.value)}>
                    <option value="today">Hoy</option>
                    <option value="7">Últimos 7 días</option>
                    <option value="30">Últimos 30 días</option>    
                        <option value="180">Últimos 180 días</option>
                        <option value="365">Últimos 365 días</option>
                      
                    </select>
                </label>
            </div>

            {/* Cargando o mostrando gráficos */}
            {loading ? (
                <p>Cargando métricas...</p>
            ) : (
                <div className="chart-container">
                    <h3>Carnes</h3>
                    <div className="chart">
                        <Doughnut data={meatData} />
                    </div>
                    <h3>Panes</h3>
                    <div className="chart">
                        <Doughnut data={breadData} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default MeatAndBreadMetrics;

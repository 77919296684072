import React, { useState } from "react";
import { Modal, Button, Form, Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";

const OrderTracker = ({ show, handleClose }) => {
  const [nroOrden, setNroOrden] = useState("");
  const [telefono, setTelefono] = useState("");
  const [resultado, setResultado] = useState(null);
  const [error, setError] = useState("");

  const handleBuscar = async () => {
    if (!nroOrden || !telefono) {
      setError("Por favor, completa ambos campos.");
      return;
    }
    setError("");
    try {
      const response = await axios.post("https://pedir-pedir.p89nps.easypanel.host/estado-orden", {
        nroOrden,
        telefono,
      });
      setResultado(response.data);
    } catch (err) {
      setError("No se encontró la orden o los datos no coinciden.");
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Rastrea tu Pedido
        <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip>
                <p>
                  Para saber el estado de tu pedido, ingresa el número de orden que te brindaron y el
                  número de teléfono por el cual lo realizaste.
                </p>
              </Tooltip>
            }
          >
            <i
              className="bi bi-info-circle"
              style={{
                marginLeft: "8px",
                color: "#007bff",
                cursor: "pointer",
              }}
            ></i>
          </OverlayTrigger>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Form>
          <Form.Group controlId="formOrderNumber">
            <Form.Label>Número de Orden</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingresa el número de tu orden"
              value={nroOrden}
              onChange={(e) => setNroOrden(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formPhoneNumber" className="mt-3">
            <Form.Label>Teléfono</Form.Label>
            <Form.Control
              type="tel"
              placeholder="Ingresa tu número de teléfono"
              value={telefono}
              onChange={(e) => setTelefono(e.target.value)}
            />
          </Form.Group>
        </Form>

        {error && <Alert variant="danger" className="mt-3">{error}</Alert>}

        {resultado && (
          <Alert variant="success" className="mt-3">
            <h5>Estado del Pedido: {resultado.estado}</h5>
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleBuscar}>
          Buscar
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OrderTracker;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Spinner, Toast } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import EditarHorarios from './EditarHorarios';

const CollectionList3 = () => {
    const [phone, setPhone] = useState("");
    const [selectedPhone, setSelectedPhone] = useState("");
    const [phoneNumbers, setPhoneNumbers] = useState([]);  // Lista de teléfonos desde la base
    const [selectedBranch, setSelectedBranch] = useState("");  // Sucursal seleccionada
    const [selectedSuc, setSelectedSuc] = useState("");  // Sucursal seleccionada
    const [editingPhone, setEditingPhone] = useState(false);  // Estado para activar el input de edición
    const [editingExtra, setEditingExtra] = useState(false);  // Estado para activar el input de edición
    const [editingPrice, setEditingPrice] = useState(false);  // Estado para activar el input de edición
    const [loading, setLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [error, setError] = useState(null);
    const [extra, setExtra] = useState({});
    const [precio, setPrecio] = useState({});
    const [newPrecio, setNewPrecio] = useState("");
    const [newExtra, setNewExtra] = useState([]);
    // Obtener números de teléfono y sucursales
    const obtenerNumeros = async () => {
        try {
            const response = await axios.get('https://pedir-pedir.p89nps.easypanel.host/phone');
            setPhoneNumbers(response.data);
            console.log("Datos de teléfonos obtenidos correctamente");
        } catch (error) {
            console.error("Error al obtener números de teléfono:", error);
            setError("Error al obtener números de teléfono. Intente nuevamente.");
        }
    };

    useEffect(() => {
        obtenerNumeros();
    }, []);


    const obtenerPrecio = async () => {
        try {
            const response = await axios.get('https://pedir-pedir.p89nps.easypanel.host/extra');
            if (response.data.length > 0) {
                setExtra(response.data[0]); // Asumiendo que el primer objeto contiene el precio extra
            } else {
                setExtra({ extraPrice: "No disponible" }); // Valor por defecto si no se encuentra
            }
            console.log("Datos de precio extra obtenidos correctamente:", response.data[0].extraPrice);
        } catch (error) {
            console.error("Error al obtener precio extra:", error);
            setError("Error al obtener precio extra. Intente nuevamente.");
        }
    };
    

    useEffect(() => {
        obtenerPrecio();
    },[]);

    const obtenerEnvio = async () => {
        try {
            const response = await axios.get('https://pedir-pedir.p89nps.easypanel.host/precio-envio');
            if (response.data.length > 0) {
                setPrecio(response.data[0]); // Asumiendo que el primer objeto contiene el precio extra
            } else {
                setPrecio({ envio: "No disponible" }); // Valor por defecto si no se encuentra
            }
            console.log("Datos de precio envio obtenidos correctamente:", response.data[0].extraPrice);
        } catch (error) {
            console.error("Error al obtener precio evio:", error);
            setError("Error al obtener precio de envio. Intente nuevamente.");
        }
    };
    

    useEffect(() => {
        obtenerEnvio();
    },[]);



    // Maneja el cambio de sucursal seleccionada
    const handleBranchChange = (event) => {
        const branchId = event.target.value;
        setSelectedBranch(branchId);

        // Buscar el teléfono correspondiente a la sucursal seleccionada
        const branchData = phoneNumbers.find(item => item.name === branchId);
        if (branchData) {
            setPhone(branchData.telefono);  // Establece el número actual de la sucursal
            setSelectedSuc(branchData.suc);
        } else {
            setPhone("Sin asignar");
        }
        setEditingPhone(false);  // Reinicia el modo de edición
    };

    //activar el modo de edicion para precio extra
    const enableEditExtra = () => {
        setEditingExtra(true);
    };
    // Activar el modo de edición
    const enableEditPhone = () => {
        setEditingPhone(true);
    };
    //activar el modo de edicion para precio envio
    const enableEditPrice = () => {
        setEditingPrice(true);
    };



    // Guardar el número de teléfono editado
    const handleSubmit = async () => {
        if (!selectedBranch) {
            setError("Seleccione una sucursal primero.");
            return;
        }
        
        setLoading(true);
        try {
            const response = await axios.put(`https://pedir-pedir.p89nps.easypanel.host/phone/${selectedSuc}/assign`, {
                telefono: selectedPhone,  // Este es el nuevo número de teléfono
                suc: selectedSuc
            });
            console.log("nro"+selectedPhone);
            console.log("suc"+selectedBranch);
            
            setToastMessage("Número de teléfono actualizado correctamente.");
            setShowToast(true);
            console.log('Número de teléfono actualizado con éxito', response.data);
            obtenerNumeros();  // Refrescar datos después de la actualización
        } catch (error) {
            console.error('Error al actualizar el número de teléfono:', error);
            setError("Error al actualizar el número de teléfono. Intente nuevamente.");
        } finally {
            setLoading(false);
            setEditingPhone(false);
        }
    };


  // Guardar el precio extra guardado
  const handleSubmitExtra = async () => {
    
    
    setLoading(true);
    try {
        const response = await axios.put(`https://pedir-pedir.p89nps.easypanel.host/extra`, {
            extraPrice: newExtra,
        });
        console.log("Precio nuevo"+newExtra);
        
        setToastMessage("Precio actualizado correctamente.");
        setShowToast(true);
        console.log('Precio actualizado con éxito', response.data);
        obtenerNumeros();  // Refrescar datos después de la actualización
    } catch (error) {
        console.error('Error al actualizar el precio:', error);
        setError("Error al actualizar el precio. Intente nuevamente.");
    } finally {
        setLoading(false);
        setEditingExtra(false);
    }
};

  // Guardar el precio extra guardado
  const handleSubmitPrecio = async () => {
    
    
    setLoading(true);
    try {
        const response = await axios.put(`https://pedir-pedir.p89nps.easypanel.host/precio-envio`, {
            envio: newPrecio,
        });
        console.log("Precio nuevo"+newPrecio);
        
        setToastMessage("Precio actualizado correctamente.");
        setShowToast(true);
        console.log('Precio actualizado con éxito', response.data);
        obtenerEnvio();  // Refrescar datos después de la actualización
    } catch (error) {
        console.error('Error al actualizar el precio:', error);
        setError("Error al actualizar el precio. Intente nuevamente.");
    } finally {
        setLoading(false);
        setEditingPrice(false);
    }
};


    return (
        <>
        <br></br>
            <Accordion flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header><h4>Asignación de Teléfonos a Sucursales:</h4></Accordion.Header>
                    <Accordion.Body>
                        {/* Desplegable para seleccionar sucursal */}
                        <label>Seleccione una sucursal:</label>
                        <select value={selectedBranch} onChange={handleBranchChange}>
                            <option value="">Seleccionar sucursal...</option>
                            {phoneNumbers.map((branch, index) => (
                                <option key={index} value={branch.name}>
                                    {`Sucursal ${branch.suc}`}
                                </option>
                            ))}
                        </select>

                        {selectedBranch && (
                            <>
                                <p>Número de teléfono actual: <b>{phone}</b></p>
                                <Button variant="primary" onClick={enableEditPhone}><i class="bi bi-pencil-square"></i></Button>

                                {editingPhone && (
                                    <div>
                                        <input
                                            placeholder="Ingrese nuevo número"
                                            value={selectedPhone}
                                            onChange={(e) => setSelectedPhone(e.target.value)}
                                        />
                                        <Button variant="success" onClick={handleSubmit} disabled={loading}>
                                            {loading ? (
                                                <>
                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                    {' '}Cargando...
                                                </>
                                            ) : (
                                                'Guardar Cambios'
                                            )}
                                        </Button>
                                    </div>
                                )}
                            </>
                        )}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
<br></br>
<Accordion flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header><h4>Precio medallon extra:</h4></Accordion.Header>
                    <Accordion.Body>
                       

                        
                            <>
                                <p>Precio medallon extra actual: <b>${extra.extraPrice}</b></p>
                                <Button variant="primary" onClick={enableEditExtra}><i class="bi bi-pencil-square"></i></Button>

                                {editingExtra && (
                                    <div>
                                        <input
                                            placeholder="Ingrese nuevo precio"
                                            value={newExtra}
                                            onChange={(e) => setNewExtra(e.target.value)}
                                        />
                                        <Button variant="success" onClick={handleSubmitExtra} disabled={loading}>
                                            {loading ? (
                                                <>
                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                    {' '}Cargando...
                                                </>
                                            ) : (
                                                'Guardar Cambios'
                                            )}
                                        </Button>
                                    </div>
                                )}
                            </>
                        
                    </Accordion.Body>
                </Accordion.Item>
                <br></br>
            </Accordion>
            <Accordion flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header><h4>Precio de envio:</h4></Accordion.Header>
                    <Accordion.Body>
                            <>
                                <p>Precio envio actual: <b>${precio.envio}</b></p>
                                <Button variant="primary" onClick={enableEditPrice}><i class="bi bi-pencil-square"></i></Button>

                                {editingPrice && (
                                    <div>
                                        <input
                                            placeholder="Ingrese nuevo precio"
                                            value={newPrecio}
                                            onChange={(e) => setNewPrecio(e.target.value)}
                                        />
                                        <Button variant="success" onClick={handleSubmitPrecio} disabled={loading}>
                                            {loading ? (
                                                <>
                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                    {' '}Cargando...
                                                </>
                                            ) : (
                                                'Guardar Cambios'
                                            )}
                                        </Button>
                                    </div>
                                )}
                            </>
                        
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <br></br>
            <Accordion flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header><h4>Horarios sucursales</h4></Accordion.Header>
                    <Accordion.Body>
                    <EditarHorarios />
                        
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            {/* Mostrar Toast de éxito */}
            <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
                <Toast.Header>
                    <strong className="me-auto">Notificación</strong>
                    <small>Justo ahora</small>
                </Toast.Header>
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>

            {/* Mostrar error si existe */}
            {error && <p style={{ color: 'red' }}>{error}</p>}

        </>
    );
};

export default CollectionList3;

import './App.css';
import ItemsListContainer from './components/ItemListContainer/ItemsListContainer';
import NavBar from './components/NavBar/NavBar';
import {BrowserRouter, Routes, Route, Router, Switch} from 'react-router-dom'
import ItemDetailContainer from './components/ItemDetailContainer/ItemDetailContainer';
import CartProvider from './components/Context/CartContext';
import { Checkout } from './components/Checkout/Checkout';
import { SelectedSizeProvider } from '../src/components/Context/SelectedContextSize';
import CartWidget from './components/CartWidget/CartWidget';
import Zonas from './components/Delivery/Zonas';
import Welcome from './components/Welcome/Welcome';
import Footer from './components/Footer/Footer';
import Login from './components/Login/Login';
import Inicio from './components/Login/Inicio';
import RegistrarVisita from './components/Visitas/Visitas';

function App() {

  const branches = [
      { id: 1, name: 'Sucursal 1' },
    { id: 2, name: 'Sucursal 2' }
  ];

  return (
    <div className="App">
<RegistrarVisita/>
      <BrowserRouter>
      <SelectedSizeProvider>
      <CartProvider>
      <NavBar/>
      <CartWidget/>
      <Routes>
      <Route path= '/home' element ={<Welcome/>}/>
        <Route path= '/' element ={<Welcome/>}/>
        <Route path='/products' element={<ItemsListContainer />} />
        <Route path= '/category/:id' element ={<ItemsListContainer/>}/>
        <Route path= '/item/:id' element ={<ItemDetailContainer/>}/>
        <Route path={"/checkout"} element={<Checkout branches={branches} />} />
       {/*  <Route path= '/delivery' element ={<Zonas/>}/> */}
       <Route path="/login" element={<Login/>} />
                <Route path="/collection-editor" element={<Inicio/>} />
      </Routes>
          </CartProvider>
          </SelectedSizeProvider>
      </BrowserRouter>
{/*       <Login/> */}
      <Footer/>
    </div> 
  );
}

export default App;

import React, { useState, useContext,useEffect } from 'react';
import axios from 'axios';
const CartContext = React.createContext('');


export const useCartContext = () => useContext(CartContext);

const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [selectedSize, setSelectedSize] = useState("doble");
  const [selectedDrink, setSelectedDrink] = useState("");  
  const [selectedDip, setSelectedDip] = useState("");
  const [anotaciones, setAnotacion] = useState("")
  const [precioExtra, setPrecioExtra] = useState([]);
  const [precioBebida, setPrecioBebida] = useState([]);
  const [error, setError] = useState(null);
  const [moduloBebida, setModuloBebida] = useState(false);  // Estado del módulo de bebidas
  const [currentPrice, setCurrentPrice] = useState(0);


  // Función para obtener el estado del módulo de bebidas
  const obtenerEstadoModuloBebida = async () => {
     try {
         const response = await axios.get('https://pedir-pedir.p89nps.easypanel.host/phone');
         if (response.data.length > 0) {
             setModuloBebida(response.data[0].modulo_bebida === 1);
             console.log("Modulo de bebida"+moduloBebida)
         } else {
             setModuloBebida(false);
         }
     } catch (error) {
         console.error("Error al obtener el estado del módulo de bebidas:", error);
     }
 };
 
 // Ejecutar la solicitud al montar el componente
 useEffect(() => {
     obtenerEstadoModuloBebida();
     console.log("Cart context ModuloBebida:"+moduloBebida)
 }, []);
  const obtenerPrecio = async () => {
    try {
        const response = await axios.get('https://pedir-pedir.p89nps.easypanel.host/extra');
        if (response.data.length > 0) {
            setPrecioExtra(response.data[0]); // Asumiendo que el primer objeto contiene el precio extra
          console.log(precioExtra);

          } else {
            setPrecioExtra({ extraPrice: "No disponible" }); // Valor por defecto si no se encuentra
        }
        console.log("Datos de precio extra obtenidos correctamente:", response.data[0].extraPrice);
    } catch (error) {
        console.error("Error al obtener precio extra:", error);
        setError("Error al obtener precio extra. Intente nuevamente.");
    }
};


useEffect(() => {
    obtenerPrecio();
},[]);





  /* const addProduct = (item, quantity, selectedSize, price, acomp, selectedDrink, selectedDip, anotaciones) => {
    const updatedCart = [...cart];
  
    // Agregamos un nuevo artículo al carrito con la información del combo y acompañamiento seleccionados
    updatedCart.push({ ...item, quantity, selectedSize, price, acomp, selectedDrink, selectedDip, anotaciones });
  
    // Actualizamos el estado del carrito
    setCart(updatedCart);
  }; */
    
  const addProduct = (item, quantity, selectedSize, price, acomp, selectedDrink, selectedDip, anotaciones, extras) => {
    let precioFinal = price;
    console.log("Medallones extra:", extras.medallones);
    console.log("Precio por medallón extra:", precioExtra.extraPrice);
    console.log("Total adicional:", extras.medallones * precioExtra.extraPrice);
    
    // Lógica para calcular precio con medallones extra
    if (extras && extras.medallones > 0) {
      // Supongamos que cada medallón extra tiene un costo adicional de $50
      precioFinal += extras.medallones * precioExtra.extraPrice;
      console.log("Total final:",precioFinal);
    }
// Lógica para calcular precio con bebida 
if (selectedDrink && selectedDrink.precio) {
  // Supongamos que cada medallón extra tiene un costo adicional de $50
  precioFinal += selectedDrink.precio;
  console.log("Total final:",precioFinal);
}

  
    const updatedCart = [...cart];
    updatedCart.push({ ...item, quantity, selectedSize, price: precioFinal, acomp, selectedDrink, selectedDip, anotaciones, extras });
  
    setCart(updatedCart);
  };
  

 

  const totalPrice = () => {
    return cart.reduce((prev, act) => prev + act.quantity * act.price, 0);
  
  };

  const totalProducts = () =>
    cart.reduce(
      (acumulador, productoActual) => acumulador + productoActual.quantity,
      0
    );

  const clearCart = () => setCart([]);

  const isInCart = (id) =>
    cart.find((product) => product.id === id) ? true : false;
    
  const removeProduct = (id, selectedSize, acomp, selectedDrink, selectedDip) => {
      if (cart.length === 1 && cart[0].id === id) {
        // Si solo hay un producto en el carrito y es el que queremos eliminar, limpiar el carrito
        setCart([]);
      } else {
        // Si hay más de un producto o el que queremos eliminar no es el único en el carrito, filtrar el producto
          setCart(cart.filter((product) => 
            product.id !== id ||
            product.selectedSize !== selectedSize ||
            product.acomp !== acomp ||
            product.selectedDrink !== selectedDrink ||
            product.selectedDip !== selectedDip
          ));
        
      }
    };
    

  return (
    <CartContext.Provider
      value={{
        clearCart,
        isInCart,
        removeProduct,
        addProduct,
        totalPrice,
        totalProducts,
        cart,
        selectedSize, 
        setSelectedSize,
        selectedDrink,
        setSelectedDrink,
        selectedDip,
        setSelectedDip,
        anotaciones,
        setAnotacion,
        precioExtra,
        precioBebida,
        moduloBebida,
        setCurrentPrice,
        currentPrice,

      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
import React, { useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CollectionList from "./CollectionEditor";
import CollectionList1 from "./CollectionEditor1";
import CollectionList2 from "./CollectionEditor2";
import CollectionList3 from './CollectionEditor3';
import Order from "./Order";
import ExportOrdersButton from "./ExportOrdersButton";
import DeliverySelection from "./DeliverySelection";
import "../Checkout/checkout.css";
import EditarHorarios from './EditarHorarios';
import ClientMetrics from '../Metrics/ClientMetrics';
import ComboMetrics from '../Metrics/ComboMetrics';
import ConversionMetrics from '../Metrics/ConversionMetrics';
import MeatAndBreadMetrics from '../Metrics/MeatAndBreadMetrics';
import VisitMetrics from '../Metrics/VisitMetrics';

const Inicio = () => {
  const [activeTab, setActiveTab] = useState('productos'); // Estado para pestaña principal
  const [subTab, setSubTab] = useState(null); // Estado para subpestaña activa

  const handleTabChange = (tab) => {
    setActiveTab(tab); // Cambia pestaña principal
    setSubTab(null); // Reinicia subpestaña al cambiar de pestaña principal
  };

  const handleSubTabChange = (tab) => {
    setSubTab(tab); // Cambia subpestaña
  };

  return (
    <>
      <Container>
        <div className="back">
          <h2>Bienvenido</h2>
          <Tabs
            activeKey={activeTab}
            onSelect={handleTabChange} // Cambia pestañas principales
            id="justify-tab-example"
            className="mb-3"
            justify
          >
            <Tab eventKey="productos" title="Productos"></Tab>
            <Tab eventKey="ordenes" title="Ordenes"></Tab>
            <Tab eventKey="cierre" title="Cierre"></Tab>
            <Tab eventKey="informacion" title={<i className="bi bi-gear"></i>}></Tab>
            {/* Pestaña nueva para Métricas */}
            <Tab eventKey="metricas" title="Métricas"></Tab>
          </Tabs>

          {/* Contenido de cada pestaña */}
          {activeTab === 'productos' && (
            <div>
              <Button
                className="btn-inicio"
                variant="secondary"
                onClick={() => handleSubTabChange('bebidas')}
              >
                Bebidas
              </Button>
              <Button
                className="btn-inicio"
                variant="secondary"
                onClick={() => handleSubTabChange('salsas')}
              >
                Salsas
              </Button>
              <Button
                className="btn-inicio"
                variant="secondary"
                onClick={() => handleSubTabChange('combos')}
              >
                Combos
              </Button>

              {/* Renderizado condicional de subpestañas */}
              {subTab === 'bebidas' && <CollectionList />}
              {subTab === 'salsas' && <CollectionList1 />}
              {subTab === 'combos' && <CollectionList2 />}
            </div>
          )}

          {activeTab === 'ordenes' && (
            <div>
              <Button
                className="btn-inicio"
                variant="secondary"
                onClick={() => handleSubTabChange('ordenes')}
              >
                Buscar orden de app
              </Button>
              <Button
                className="btn-inicio"
                variant="secondary"
                onClick={() => handleSubTabChange('delivery')}
              >
                Delivery
              </Button>

              {/* Renderizado condicional de subpestañas */}
              {subTab === 'ordenes' && <Order />}
              {subTab === 'delivery' && <DeliverySelection />}
            </div>
          )}

          {activeTab === 'cierre' && (
            <div>
              <ExportOrdersButton />
            </div>
          )}

          {activeTab === 'informacion' && <CollectionList3 />}

          {/* Contenido de la nueva pestaña de Métricas */}
          {activeTab === 'metricas' && (
            <div>
              <Button
                className="btn-inicio"
                variant="secondary"
                onClick={() => handleSubTabChange('Ordenes')}
              >
                Métrica de Ordenes
              </Button>
              <Button
                className="btn-inicio"
                variant="secondary"
                onClick={() => handleSubTabChange('Combos')}
              >
                Métrica de Combos
              </Button>
              <Button
                className="btn-inicio"
                variant="secondary"
                onClick={() => handleSubTabChange('Clientes')}
              >
                Métrica de Clientes
              </Button>
              <Button
                className="btn-inicio"
                variant="secondary"
                onClick={() => handleSubTabChange('Prima')}
              >
                Métrica de Carnes y Panes
              </Button>
              <Button
                className="btn-inicio"
                variant="secondary"
                onClick={() => handleSubTabChange('Visitas')}
              >
                Cantidad de visitas a la web
              </Button>
              {/* Renderizado condicional de subpestañas de métricas */}
              {subTab === 'Ordenes' && <ConversionMetrics />}
              {subTab === 'Combos' && <ComboMetrics />}
              {subTab === 'Clientes' && <ClientMetrics/>}
              {subTab === 'Prima' && <MeatAndBreadMetrics/>}
              {subTab === 'Visitas' && <VisitMetrics />}

            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default Inicio;

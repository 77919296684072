import React, { useState } from "react";
import Logo from "../Logo/Logo";
import { Link } from "react-router-dom";
import Zonas from "../Delivery/Zonas";
import OrderTracker from "../OrderTracker/OrderTracker";
import "../Welcome/Welcome.css";

const NavBar = () => {
  const [showModal, setShowModal] = useState(false);

  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-body-tertiary" data-bs-theme="dark">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <Logo />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/home">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/category/combos"}>
                  Combos
                </Link>
              </li>
              <li className="nav-item">
                <span className="nav-link responsive" style={{ cursor:"pointer"}}>
                  <Zonas zona={"Zona de delivery"} />
                </span>
              </li>
              <li className="nav-item responsive">
                {/* Botón para abrir el modal */}
                <button className="nav-link btn btn-link" onClick={handleOpen}>
                  Tu pedido
                </button>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="https://www.instagram.com/burbar.ok/?hl=es"
                  target="_blank"
                >
                  <i className="bi bi-instagram"></i>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/login"}>
                  <i className="bi bi-gear"></i>
                </Link>
              </li>
            </ul>
            <b style={{ color: "White" }}>
              Pedir Nunca Fue Tan Fácil App: Burbar
            </b>
          </div>
        </div>
      </nav>

      {/* Modal para rastrear pedidos */}
      <OrderTracker show={showModal} handleClose={handleClose} />
    </div>
  );
};

export default NavBar;

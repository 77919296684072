import { useEffect } from 'react';

const RegistrarVisita = () => {
  useEffect(() => {
    const registrarVisita = async () => {
      try {
        const response = await fetch('https://pedir-pedir.p89nps.easypanel.host/register-visit', { method: 'GET' });
        if (!response.ok) {
          console.error('Error al registrar la visita');
        }
      } catch (error) {
        console.error('Error en la solicitud:', error);
    }
    console.log("Visita registrada")
};

    registrarVisita();
  }, []); // Ejecuta solo una vez al cargar el componente

  return null;
};

export default RegistrarVisita;
